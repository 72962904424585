@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/roboto-v30-latin-300.woff2");
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/roboto-v30-latin-regular.woff2");
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/roboto-v30-latin-500.woff2");
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/roboto-v30-latin-700.woff2");
}
